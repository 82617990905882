import './App.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CountdownTimer from './CountdownTimer';
import Fade from 'react-reveal/Fade';
const App = () => {
  const THREE_DAYS_IN_MS = 20 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
  return (

    <div className='uc-section-wrapper'>
      <Container className='uc-section'>
        <Row className='uc-section_logo-row'>
          <Col  >
            <Fade top delay={100}>
              <img src={"./logo.jpeg"} className="uc-section_logo" alt="logo" />
              <h1>OMSAI DEV FOUNDATION</h1>
            </Fade>
          </Col>

        </Row>
        <Fade delay={1000}>
          <Row className='uc-section_row'>
            <Col md={12}> 
              <div className='uc-section_row_header'> 
                <div className='address'>
                  <h3>Contact Address</h3>
                  <p>No 07, Puthumettu Street,</p>
                  <p>Muthu Ramalingapuram,</p>
                  <p>Baikara, Pasumalai, Madurai - 625004</p>
                </div>

                <div className='uc-section_row_header_service'>
                  <a>Email : omsaidevfoundation@gmail.com</a> 
                </div>
              </div>
            </Col>
          </Row>
        </Fade>

      </Container>
    </div>

  );
}

export default App;
